import { Injectable } from "@angular/core";
import { Workbook } from "exceljs";
import * as fs from "file-saver";
import { DatePipe } from "@angular/common";
import {
  CITY_EXCEL_HEADER,
  FIFA_TICKETS_EXCEL_HEADER,
  REGION_EXCEL_HEADER,
  REWARD_VOUCHERS_EXCEL_HEADER,
  STATE_EXCEL_HEADER,
  UNIQUE_CODE_EXCEL_HEADER,
} from "src/app/hubadmin/shared/models/mock-data";


@Injectable({
  providedIn: "root",
})
export class ExportExcelService {
  stateHeaders = STATE_EXCEL_HEADER;
  regionHeaders = REGION_EXCEL_HEADER;
  cityHeaders = CITY_EXCEL_HEADER;
  uniqueCodeHeaders = UNIQUE_CODE_EXCEL_HEADER;
  FifaTicketsHeaders = FIFA_TICKETS_EXCEL_HEADER;
  rewardVouchersHeaders = REWARD_VOUCHERS_EXCEL_HEADER;
  constructor(
    private datePipe: DatePipe,
  ) {
  }

  pipe = new DatePipe("en-US");

  exportBrandExcel(headers, title, dropDown) {
    console.log(dropDown);
    const finalHeaders = headers;
    let categoryHeader = ['Category'];
    let subCategoryHeader = ['Sub Category'];
    let isLoyaltyHeader = ['is Loyalty'];
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Add New Details", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      // cell.protection = { locked: true };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });



    /*********************category Starts**********************************/
    /** adding category drop down */
    let categoryWorksheet = workbook.addWorksheet("Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let categoryHeaderRow = categoryWorksheet.addRow(categoryHeader);

    // Cell Style : Fill and Border
    categoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    categoryWorksheet.columns = [{ header: 'Category', key: "category_name" }];

    categoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });



    /**************************************************************/

    let subCategoryWorksheet = workbook.addWorksheet("Sub_Category", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let subCategoryHeaderRow = subCategoryWorksheet.addRow(subCategoryHeader);

    // Cell Style : Fill and Border
    subCategoryHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 8,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    subCategoryWorksheet.columns = [{ header: "Sub Category", key: "sub_category_name" }];

    dropDown.subCategory.forEach((d) => {

      let row = subCategoryWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 11,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    subCategoryWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let subCategoryFormulae = [`${subCategoryWorksheet.name}!A2:A${dropDown.subCategory.length}`];

    //add vinod is loyalty drop

    let isLoyaltyWorksheet = workbook.addWorksheet("Is_Loyalty", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let isLoyaltyHeaderRow = isLoyaltyWorksheet.addRow(isLoyaltyHeader);

    // Cell Style : Fill and Border
    isLoyaltyHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    isLoyaltyWorksheet.columns = [{ header: "Is Loayalty", key: "isLoyality_name", values: dropDown.isLoyaltyDrop.value, }];

    dropDown.isLoyaltyDrop.forEach((d) => {

      let row = isLoyaltyWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 11,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    let isLoyaltyFormulae = [`${isLoyaltyWorksheet.name}!A2:A${dropDown.isLoyaltyDrop.length + 1}`];

    for (var i = 2; i <= 50; i++) {
      worksheet.getCell(`H${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: isLoyaltyFormulae,
        errorStyle: "error",
        errorTitle: "choose Loyality type",
        error: "please select",
        showErrorMessage: true,
      };
    }

    isLoyaltyWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    //end loyalty vinod



    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        title + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

  exportRegionExcel() {
    const finalHeaders = this.regionHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Region Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Region_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  exportStateExcel(region: any[]) {
    const finalHeaders = this.stateHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("State Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /************REGION WORKSHEET***********************/

    let regionWorksheet = workbook.addWorksheet("Regions", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let regionHeaderRow = regionWorksheet.addRow(this.regionHeaders);

    // Cell Style : Fill and Border
    regionHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    regionWorksheet.columns = [{ header: "Regions", key: "region_name" }];

    region.forEach((d) => {
      let row = regionWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 11,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    regionWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let regionFormulae = [`${regionWorksheet.name}!A2:A${region.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: regionFormulae,
        errorStyle: "error",
        errorTitle: "choose a role",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "State_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }





  exportCityExcel(state: any[]) {
    const finalHeaders = this.cityHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("City Add Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    /**************************************************************/

    let stateWorksheet = workbook.addWorksheet("States", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let stateHeaderRow = stateWorksheet.addRow(this.stateHeaders);

    // Cell Style : Fill and Border
    stateHeaderRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "9999FF" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    stateWorksheet.columns = [{ header: "States", key: "state_name" }];

    state.forEach((d) => {
      let row = stateWorksheet.addRow(d);
      (row.font = {
        name: "Calibri",
        size: 11,
      }),
        (row.alignment = {
          vertical: "middle",
          horizontal: "center",
        });
    });

    stateWorksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    let stateFormulae = [`${stateWorksheet.name}!A2:A${state.length + 1}`];

    for (var i = 2; i < 50; i++) {
      worksheet.getCell(`B${i}`).dataValidation = {
        type: "list",
        allowBlank: false,
        formulae: stateFormulae,
        errorStyle: "error",
        errorTitle: "choose a state",
        error: "please select",
        showErrorMessage: true,
      };
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "City_Excel_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }


  exportUniqueCodeExcel() {
    const finalHeaders = this.uniqueCodeHeaders;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Unique Code Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF8989" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Unique_Code_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportFifaTicketExcel() {
    const finalHeaders = this.FifaTicketsHeaders
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("FIFA Ticket Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF8989" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "FIFA_Ticket_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }


  exportRewardVouchresExcel() {
    const finalHeaders = this.rewardVouchersHeaders
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Reward Vouchers Excel", {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });
    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF8989" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        "Reawrd_Vouchers_Excel_" +
        this.datePipe.transform(new Date(), "medium") +
        ".xlsx"
      );
    });
  }

  commonDownloadWithData(excelData) {
    const finalHeaders = excelData.headers;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(excelData.file, {
      pageSetup: {
        horizontalCentered: true,
        verticalCentered: true,
        paperSize: 9,
        orientation: "portrait",
        margins: {
          left: 0.3149606,
          right: 0.3149606,
          top: 0.3543307,
          bottom: 0.3543307,
          header: 0.3149606,
          footer: 0.3149606,
        },
      },
    });

    //Add Header Row
    let headerRow = worksheet.addRow(finalHeaders);
    // Add Geo Header Row

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FF8989" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.font = {
        bold: true,
        name: "Calibri",
        size: 11,
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      cell.protection = {
        locked: false,
      };
    });



    worksheet.columns = excelData.column
    worksheet.columns.forEach(function (column, i) {
      column.width = 26;
    });

    if (excelData.body.length > 0) {
      excelData.body.forEach((d) => {
        let row = worksheet.addRow(d);
        (row.font = {
          bold: false,
          name: "Arial",
          size: 11,
        }),
          (row.alignment = {
            vertical: "middle",
            horizontal: "center",
          });
      });
    }

    // only if hasDropdown param is true this code is executed
    if (excelData.hasDropdown == true) {
      excelData.dropDowns.forEach(dropdown => {

        let commonWorksheet = workbook.addWorksheet(dropdown.name, {
          pageSetup: {
            horizontalCentered: true,
            verticalCentered: true,
            paperSize: 9,
            orientation: "portrait",
            margins: {
              left: 0.3149606,
              right: 0.3149606,
              top: 0.3543307,
              bottom: 0.3543307,
              header: 0.3149606,
              footer: 0.3149606,
            },
          },
        });

        //Add Header Row
        let commonHeaderRow = commonWorksheet.addRow(dropdown.name);

        // Cell Style : Fill and Border
        commonHeaderRow.eachCell((cell, number) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FF8989" },
            bgColor: { argb: "FF0000FF" },
          };
          cell.font = {
            bold: true,
            name: "Calibri",
            size: 11,
          };
          cell.alignment = {
            vertical: "middle",
            horizontal: "center",
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        commonWorksheet.columns = [{ header: dropdown.name, key: dropdown.key }];

        dropdown.body.forEach((d) => {
          let row = commonWorksheet.addRow(d);
          (row.font = {
            name: "Calibri",
            size: 11,
          }),
            (row.alignment = {
              vertical: "middle",
              horizontal: "center",
            });
        });

        commonWorksheet.columns.forEach(function (column, i) {
          column.width = 26;
        });


        let Formulae = [`${dropdown.name}!A2:A${dropdown.body.length + 1}`];

        for (var i = 2; i < 50; i++) {
          worksheet.getCell(`${dropdown.column}${i}`).dataValidation = {
            type: "list",
            allowBlank: false,
            formulae: Formulae,
            errorStyle: "error",
            errorTitle: "choose a status",
            error: "please select",
            showErrorMessage: true,
          };
        }

      });
    }

    // Add Data and Conditional Formatting
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      fs.saveAs(
        blob,
        `${excelData.file}` + "_" + this.datePipe.transform(new Date(), "medium") + ".xlsx"
      );
    });
  }

}