import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class ApiConfiguration {


  //staging
  baseUrl = "https://appapi.budspacev4.bigcityvoucher.co.in/v1";
  //production
  //baseUrl = "https://staging29api.bigcityexperiences.co.in/v1"




  login = this.baseUrl + "/staff/login";


  //Agent pannel
  listallinvoices = this.baseUrl + "/staff/listallinvoices";
  viewinvoicetransactions = this.baseUrl + "/staff/viewinvoicetransactions";
  uploadinvoicedata = this.baseUrl + "/staff/uploadinvoicedata";
  productList = this.baseUrl + "/staff/getactiveproducts";
  reasons = this.baseUrl + "/staff/reasons"
  updateinvoicestatus = this.baseUrl + "/staff/updateinvoicestatus";
  weekList = this.baseUrl + "/staff/getweeksconfig"
  downloadInvoiceExcel = this.baseUrl + "/staff/downloadinvoicelist"
  getOutletNameList = this.baseUrl + "/vouchers/store-locator";



  //masterSetup
  getSatateList = this.baseUrl + "/user/getstatelist";
  getCitiesList = this.baseUrl + "/user/getcitylist";


  //Unique Code
  uniqueCodeList = this.baseUrl + "/vouchers/list";
  uploadUniqueCodeExcel = this.baseUrl + "/vouchers/import";


  //FIFA Tickets
  fifaTicketList = this.baseUrl + "/vouchers/get-ticket";
  uploadFifaTicketExcel = this.baseUrl + "/vouchers/ticket-import";



  //Rewards Vouchers
  rewardVouchersList = this.baseUrl + "/vouchers/get-reward?";
  uploadRewardVouchersExcel = this.baseUrl + "/vouchers/import-reward";

  //Weekly Reports
  getReportsList = this.baseUrl + "/weekly-report/list?";
  downloadWeeklyReports = this.baseUrl + "/weekly-report/export?";
  getweekList = this.baseUrl + "/weekly-report/get-week-config"


  //Weekly Winners
  getRewardByIdList = this.baseUrl + "/reward/getrewardbyweekid?program_id=1";
  getWeeklyDrawWinners = this.baseUrl + "/reward/weeklydrawwinnerannounce?";

  //rewards
  uploadRewardsExcel = this.baseUrl + "/reward/import-rewards?program_id=1";


  //Redemption 
  getOrderList = this.baseUrl + "/redemption/get-order-listings";
  getDownOrderData = this.baseUrl + "/redemption/download-order";
  orderOfferCodeUp = this.baseUrl + "/redemption/order-offer-code-upload";
  changeOrderStatusByForm = this.baseUrl + "/redemption/bulk-status-update?program_id=1";
  changeOrderStatusByeExcel = this.baseUrl + "/redemption/order-statusupload?program_id=1";
  getCancelReasonDropDown = this.baseUrl + "/redemption/get-order-cancelling-reason?program_id=1";



  //Reports
  loginReport = this.baseUrl + "/adminreport/login-report?program_id=1";
  refferalReport = this.baseUrl + "/adminreport/refferel-report?program_id=1";
  earningReport = this.baseUrl + "/adminreport/earning-report?program_id=1";
  pointBalanceReport = this.baseUrl + "/adminreport/point-balance?program_id=1";
  weeklyDrawWinnerReport = this.baseUrl + "/adminreport/weeklydrawwinnerreport?";
  happyHoursReport = this.baseUrl + "/adminreport/happyhoursreport?";
  lotteryTicketReport = this.baseUrl + "/adminreport/lotteryticketreport?";
  assuredRewardReport = this.baseUrl + "/adminreport/assuredrewardreport?";
  redemptionReport = this.baseUrl + "/adminreport/redemptionreport?";
  gamezoneReport = this.baseUrl + "/adminreport/gamezonereport?";
  inventoryReport = this.baseUrl + "/adminreport/inventoryreport?";
  customerCheckInReport = this.baseUrl + "/adminreport/checkinreport?";
  pointledgerReport = this.baseUrl + "/adminreport/pointledgerreport?";





}
export interface ApiConfigurationInterface { }
